<template>
  <a-drawer 
		class="Honor-add" 
		title="企业荣誉相关" 
		@close="onClose" 
		:width="720" 
		:visible="addVisiable"
		:maskClosable="false"
	>

    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="荣誉名称" prop="title" v-bind="formItemLayout">
        <a-input v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item label="荣誉描述" prop="description" v-bind="formItemLayout">
        <a-textarea placeholder="荣誉描述" v-model="form.description" :rows="3" />
      </a-form-model-item>
      <a-form-model-item label="荣誉证书" prop="image" v-bind="formItemLayout">
        <UploadImgs :path="IMAGE_HONOR" :file-list="fileList" @_change="handleUploadImgChange"></UploadImgs>
      </a-form-model-item>
      <a-form-model-item label="获得时间" prop="awardDate" v-bind="formItemLayout" >
        <a-date-picker format="YYYY-MM-DD" v-model="form.awardDate" />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
// import moment from "moment"
import {IMAGE_HONOR} from "../../../utils/upload-path-map"
import UploadImgs from "../../../components/upload-imgs/UploadImgs"

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}

export default {
  name: "HonorAdd",
  components: {UploadImgs},
  props: {
    addVisiable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formItemLayout,
      IMAGE_HONOR,
      fileList: [],
      form: {
        // id: undefined,
        title: undefined,
        description: undefined,
        image: undefined,
        awardDate: undefined
      },
      rules: {
        title: [
          { required: true, message: '荣誉名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.reset();
      this.$emit("close");
    },
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
      this.fileList = [];
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let awardDate = this.form.awardDate?this.form.awardDate.toDate():null;
          // console.log("添加企业荣誉相关", {...this.form, awardDate});
          this.$post("company-honor", {...this.form, awardDate}).then(r => {
            console.log("添加企业荣誉相关", r.data);
            this.$emit("success");
            this.reset();
          })
        } else {
          return false;
        }
      });
    },
    handleUploadImgChange(fileList, urls) {
      console.log("上传图片组件传过来的值", fileList, urls);
      // this.form.image = JSON.stringify(urls);
      urls = urls.map(item => {return {url: item.filePath}});
      this.form.image = JSON.stringify(urls);
      this.fileList = fileList;
    },
  }
}
</script>